import React from 'react'
import PageHeader from '../components/PageHeader';
import PageContent from '../components/PageContent';
import PageTitle from '../components/PageTitle';
import PlaylistPlayer from '../components/players/Playlist';
import { goodFieldDebut, futureMe, surfaceTension } from '../playlists/playlists';

const PlaylistPage = () => {
    return (
        <div>
            <PageHeader>
                <PageTitle>Playlist</PageTitle>
                <p>A group of tracks displayed together.</p>
            </PageHeader>
            <PageContent>
                <div className="w-full flex flex-row mb-4 xl:mb-8 px-1 md:px-0">
                    <div className="w-full px-6 py-10 flex flex-col items-start justify-between bg-white rounded-lg mb-6 xl:mb-0 shadow-lg xl:flex-row">
                        <div className="w-full xl:w-5/12 mb-6">
                            <h3 className="text-gray-700 uppercase font-bold text-lg mb-4">Description</h3>
                            <div className="text-sm text-gray-600">
                                <p className="mb-3 max-w-xl">This component is a simple button with play and pause functionality, along with the <span className="font-extrabold">progress</span> of the audio track indicated on the outside border.</p>
                                <p className="mb-3 max-w-xl"><span className="font-extrabold">Primary</span> and <span className="font-extrabold">secondary</span> colors can be passed as props to control the current progress and unplayed progress colors respectively, and a <span className="font-extrabold">size</span> prop can be used to set the width and height in pixels.</p>
                                <p className="max-w-xl">Passing an audio source prop will use an internal WebAudio context to handle song playback, or it can be omitted and instead passed <span className="font-extrabold">playing</span> and <span className="font-extrabold">percentage</span> props to be used as a display component only, with the audio context handled at a higher level.</p>
                            </div>
                        </div>
                        <div className="w-full flex flex-row mb-4 xl:w-7/12" style={{ height: 300 }}>
                            <PlaylistPlayer playlist={surfaceTension} />

                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-row mb-4 xl:mb-8 px-1 md:px-0">
                    <div className="w-full px-6 py-10 flex flex-col items-start justify-center min-w-0 bg-white rounded-lg mb-6 xl:mb-0 shadow-lg xl:flex-row">
                        <div className="w-full xl:w-7/12" style={{ height: 400 }} >
                            <PlaylistPlayer playlist={goodFieldDebut} />
                        </div>
                        <div className="w-full xl:w-5/12">
                            Details about playlist...
                        </div>

                    </div>
                </div>

            </PageContent>
        </div>
    )
}

export default PlaylistPage

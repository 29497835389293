import React from 'react'

const PageTitle = ({ children }) => {
    return (
        <div className="flex flex-row justify-start items-center">
            {/* <svg className="mr-4" xmlns="http://www.w3.org/2000/svg" width="120" height="54"><g fill="none" fillRule="evenodd" strokeLinecap="round" strokeWidth="15"><path fill="#FFF" stroke="#FFF" d="M112 21v12M86 8v38" /><path fill="#FFF" stroke="#FFF" d="M60 19v16" /><path fill="#FFF" stroke="#FFF" d="M34 13v29M8 24v7" /></g></svg> */}
            <h1 className="text-4xl lg:text-6xl font-medium">
                {children}
            </h1>
        </div>

    )
}

export default PageTitle

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useAudioState } from '../../state/AudioWrapper'
import Waveform from '../primitives/Waveform'

const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 90px;
    z-index: 99999999;
`
const FooterPlayer = ({ primaryColor, secondaryColor, timeline, audioTrack }) => {

    const {
        context,
        // audio,
        currentAudioSource,
        // isPlaying,
        positionPercent,
        // positionSeconds,
        // duration,
        // setPosition,
        loadAudio,
        // playAudio,
        // pauseAudio,
        title,
        artist,
        imageSource,
        setTitle,
        setArtist,
        setImageSource
    } = useAudioState()

    useEffect(() => {
        if (!currentAudioSource && audioTrack.audioSource) {
            loadAudio(audioTrack.audioSource)
            setTitle(audioTrack?.title)
            setArtist(audioTrack?.artist)
            setImageSource(audioTrack?.imageSource)
        }
    }, [audioTrack, loadAudio])

    return (
        <Container style={{ background: secondaryColor || "#2D3748" }} className="bg-gray-800 text-white flex flex-col justify-center items-center">
            <div className="w-full max-w-screen-xl flex flex-row justify-center items-center mx-auto">
                <div>
                    <div>
                        <p className="font-bold">{title}</p>
                        <p>{artist}</p>
                    </div>
                </div>

                <div className="w-8/12 mx-auto">
                    {!currentAudioSource &&
                        <div className="flex justify-center items-center text-lg">Select an audio track</div>
                    }
                    {currentAudioSource &&
                        (timeline === 'waveform'
                            ? (<Waveform color={primaryColor || "#EDF2F7"} audioContext={context} audioSrc={currentAudioSource} positionPercent={positionPercent} />)
                            : <div>PROGRESS BAR</div>)
                    }
                </div>
            </div>
        </Container>
    )
}

export default FooterPlayer

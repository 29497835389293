import React, { useRef, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import Color from 'color'
import styled from 'styled-components'
import { useAudioState } from '../../state/AudioWrapper'

const ProgressCircle = styled.circle`
    stroke: ${props => props.primaryColor};
`

const Icon = styled.div`
`

const AudioButtonSVG = styled.svg`
    &:hover ${ProgressCircle} {
        stroke: ${props => Color(props.primaryColor).darken(.1)}
    }

    &:hover .fla-audio-button-icon {
        transform: scale3d(1.06, 1.06, 1.06);
    }

    &:hover .fla-audio-button-icon > * {
        color: ${props => Color(props.primaryColor).darken(.1)};
    }
`

const ButtonPlayer = ({ primaryColor, secondaryColor, displayBackgroundImage, audioTrack }) => {

    const primary = primaryColor || '#4299E1'
    const secondary = secondaryColor || '#C3DAFE'

    const [containerWidth, setContainerWidth] = useState()

    const container = useRef()

    const {
        // context,
        // audio,
        currentAudioSource,
        isPlaying,
        positionPercent,
        // positionSeconds,
        // duration,
        // setPosition,
        loadAudio,
        playAudio,
        pauseAudio,
        setTitle,
        setArtist,
        setAlbumTitle,
        setImageSource
    } = useAudioState()

    useEffect(() => {
        setContainerWidth(container.current?.clientWidth)
    }, [container])

    // useEffect(() => {
    //     setTitle(audioTrack?.title)
    //     setArtist(audioTrack?.artist)
    //     setAlbumTitle(audioTrack?.albumTitle)
    //     setImageSource(audioTrack?.imageSource)
    // }, [audioTrack])

    // useEffect(() => {

    // }, [albumTitle, artist, setAlbumTitle, setArtist, setTitle, title])

    let svg
    if (containerWidth) {
        const isCurrentSong = currentAudioSource === audioTrack?.audioSource;
        const btnSize = containerWidth;
        const strokeWidth = btnSize / 10;
        const radius = (btnSize - strokeWidth) / 2;
        const viewBox = `0 0 ${btnSize} ${btnSize}`;
        const dashArray = radius * Math.PI * 2;
        const percent = currentAudioSource === audioTrack?.audioSource ? positionPercent : 0;
        const dashOffset = dashArray - dashArray * percent / 100;
        svg = (<AudioButtonSVG
            onClick={() => {
                if (currentAudioSource !== audioTrack?.audioSource) {
                    setTitle(audioTrack?.title)
                    setArtist(audioTrack?.artist)
                    setAlbumTitle(audioTrack?.albumTitle)
                    setImageSource(audioTrack?.imageSource)
                    loadAudio(audioTrack?.audioSource)
                    playAudio()
                } else {
                    if (isPlaying) {
                        pauseAudio()
                    } else {
                        playAudio()
                    }
                }

            }}
            primaryColor={primary}
            className="cursor-pointer object-contain"
            width={btnSize}
            height={btnSize}
            viewBox={viewBox}>
            <circle
                style={{ fill: audioTrack?.imageSource ? 'none' : '#fff', stroke: secondary }}
                cx={btnSize / 2}
                cy={btnSize / 2}
                r={radius}
                strokeWidth={`${strokeWidth}px`} />
            <ProgressCircle
                style={{
                    fill: 'none',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeDasharray: dashArray,
                    strokeDashoffset: dashOffset
                }}
                primaryColor={primary}
                cx={btnSize / 2}
                cy={btnSize / 2}
                r={radius}
                strokeWidth={`${strokeWidth}px`}
                transform={`rotate(-90 ${btnSize / 2} ${btnSize / 2})`}
            />
            <foreignObject width={btnSize} height={btnSize}>
                <Icon color={primary}
                    className="rounded-full flex justify-center items-center h-full w-full">
                    {(!isCurrentSong || (isCurrentSong && !isPlaying)) &&
                        <FontAwesomeIcon className="fla-audio-button-icon" icon={faPlay} transform="right-1" style={{ fontSize: btnSize / 3, color: secondary }} />}
                    {(isCurrentSong && isPlaying) &&
                        <FontAwesomeIcon icon={faPause} className="fla-audio-button-icon" style={{ fontSize: btnSize / 3, color: secondary }} />}
                </Icon>
            </foreignObject>
        </AudioButtonSVG>)
    }

    return (
        <div ref={container} className="w-full flex justify-center items-center">
            <div style={{ background: `${audioTrack?.imageSource && displayBackgroundImage ? `linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(255, 255, 255, 0.65)), url(${audioTrack?.imageSource})` : ''}`, backgroundSize: 'contain' }} className="p-3 rounded-lg bg-no-repeat">
                {svg}
            </div>
        </div>
    )
}

export default ButtonPlayer

import { useState, useEffect } from 'react'
import { useAudioDetails } from './useAudioDetails'
export const useWebAudio = () => {
    const [context, setContext] = useState()
    const [audio] = useState(new Audio())
    const [isPlaying, setIsPlaying] = useState(false)
    const [currentAudioSource, setCurrentAudioSource] = useState()
    const [durationSeconds, setDurationSeconds] = useState()
    const [positionValPercent, setPositionValPercent] = useState(0)
    const [positionValSeconds, setPositionValSeconds] = useState(0)

    const { setDuration } = useAudioDetails()

    useEffect(() => {
        audio.setAttribute('preload', 'metadata')
        const loadedMetadata = () => {
            setDurationSeconds(audio.duration)
            setDuration(audio.durationSeconds)
        }
        const onPlay = () => {
            setIsPlaying(true)
        }
        const onPause = () => {
            setIsPlaying(false)
        }
        const onTimeUpdate = () => {
            if (!audio.duration) return
            setPositionValSeconds(audio.currentTime)
            setPositionValPercent(audio.currentTime / audio.duration * 100)
        }

        audio.addEventListener('timeupdate', onTimeUpdate)
        audio.addEventListener('loadedmetadata', loadedMetadata)
        audio.addEventListener('play', onPlay)
        audio.addEventListener('pause', onPause)

        return (() => {
            audio.removeEventListener('timeupdate', onTimeUpdate)
            audio.removeEventListener('loadedmetadata', loadedMetadata)
            audio.removeEventListener('play', onPlay)
            audio.removeEventListener('pause', onPause)
        })
    }, [audio])

    useEffect(() => {
        if (!context) return
        const track = context.createMediaElementSource(audio)
        track.connect(context.destination);

        return (() => {
            context.close()
        })
    }, [context])

    const loadAudio = (src) => {
        // audio.currentTime = 0; // not working
        // setPositionValSeconds(0);
        audio.src = src
        audio.load()
        setCurrentAudioSource(src)
    }

    const setPositionSeconds = (seconds) => {
        audio.currentTime = seconds;
        setPositionValSeconds(seconds);
    }

    const setPositionPercent = (percent) => {
        // console.log(percent)
        // audio.currentTime = seconds;
        setPositionValPercent(percent);
    }

    const playAudio = () => {
        if (!context) setContext(new AudioContext)
        audio.play()
    }

    const pauseAudio = () => {
        audio.pause()
    }

    return { context, audio, currentAudioSource, isPlaying, positionPercent: positionValPercent, positionSeconds: positionValSeconds, setPositionSeconds, setPositionPercent, loadAudio, playAudio, pauseAudio }

}
import React from 'react'
import ProgressBar from './ProgressBar'

const PlaylistHeader = ({ title, subtitle, imageSource, trackCount }) => {
    return (
        <div className="flex flex-row w-full h-full justify-between items-center">
            <div className="flex flex-row justify-start items-center px-2 w-6/12 h-full">
                {imageSource && <img src={imageSource} alt={`${title} artwork`} className="w-auto h-full border object-contain rounded-md mr-3" />}
                <div className="w-2/3 py-2">
                    <h2 className="font-bold text-lg text-gray-800">{title}</h2>
                    <h3 className="text-gray-700">{subtitle}</h3>
                    <p className="text-sm font-light text-gray-600">{trackCount} Tracks</p>
                </div>
            </div>
            <div className="w-5/12 px-4">
                {/* <ProgressBar
                    secondaryColor="#E2E8F0"
                    primaryColor="#718096"
                    percentComplete={20}
                    onSeek={(percentage) => console.log(percentage)}
                /> */}
            </div>
        </div>
    )
}

export default PlaylistHeader

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import { useAudioState } from '../../state/AudioWrapper'
const AudioListItem = ({ primaryColor, secondaryColor, displayBackgroundImage, audioTrack, playing, selected, onTogglePlay }) => {
    const {
        // context,
        // audio,
        currentAudioSource,
        isPlaying,
        positionPercent,
        // positionSeconds,
        duration,
        // setPosition,
        loadAudio,
        playAudio,
        pauseAudio,
        setTitle,
        setArtist,
        setAlbumTitle,
        setImageSource
    } = useAudioState()
    return (
        <div className={`${selected && 'bg-gray-200'} flex flex-row justify-between items-center py-2 px-2 border-b w-full`}>
            <div
                className="flex flex-row justify-start items-center cursor-pointer text-gray-700 hover:text-gray-900"
                onClick={() => onTogglePlay(audioTrack.audioSource)}
            >
                <div className="mr-3">
                    {playing && <FontAwesomeIcon icon={faPause} size="sm" />}
                    {!playing && <FontAwesomeIcon icon={faPlay} size="sm" />}
                </div>
                <div className="flex flex-col justify-start items-start">
                    <div className="text-sm font-semibold">{audioTrack.title}</div>
                    <div className="text-xs">{audioTrack.artist}</div>
                </div>
            </div>
            <div className="text-xs">
                {audioTrack.duration}
            </div>
        </div >
    )
}

export default AudioListItem

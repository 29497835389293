import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'


const bounceTransition = {
    y: {
        duration: 0.4,
        yoyo: Infinity,
        ease: "easeOut",
    },
    backgroundColor: {
        duration: .3,
        yoyo: Infinity,
        ease: "easeOut",
        repeatDelay: 0.8,
    },
}

const Item = styled(motion.div)`
  width: 10px;
  margin: 0 .2rem;
  border-radius: 15px;
  background-color: ${props => props.color};
`

const Loading = () => {

    return (
        <div className="flex justify-center items-center h-full">
            <Item transition={{ ...bounceTransition, delay: 0 }}
                color="#4299E1"
                animate={{
                    y: ["20%", "-20%"],
                }}
                style={{ height: 15 }}
            />
            <Item transition={{ ...bounceTransition, delay: .25 }}
                color="#4299E1"
                animate={{
                    y: ["30%", "-30%"],
                }}
                style={{ height: 30 }}
            />
            <Item transition={{ ...bounceTransition, delay: .5 }}
                color="#4299E1"
                animate={{
                    y: ["60%", "-60%"],
                    backgroundColor: ['#BEE3F8', '#EBF8FF'],
                }}
                style={{ height: 20 }}
            />
            <Item transition={{ ...bounceTransition, delay: .75 }}
                color="#4299E1"
                animate={{
                    y: ["10%", "-10%"],
                }}
                style={{ height: 40 }}
            />
            <Item transition={{ ...bounceTransition, delay: 1 }}
                color="#4299E1"
                animate={{
                    y: ["30%", "-30%"],
                }}
                style={{ height: 18 }}
            />
        </div>
    )
}

export default Loading

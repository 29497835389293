import React from 'react'

const PageHeader = ({ children }) => {
    return (
        <div className="pt-4 pb-10 lg:px-10 lg:pb-16 w-full bg-gray-800 px-6 py-4 text-white">
            <div className="mx-auto w-full max-w-6xl px-0 xl:px-4">
                {children}
            </div>
        </div>
    )
}

export default PageHeader

const pad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.round((time - minutes * 60));
    return `${minutes}:${pad(seconds, 2)}`
}

export const getAudioDuration = (source) => {
    return new Promise((resolve) => {
        const loadedMetadata = () => {
            resolve(formatTime(audio.duration))
        }
        const audio = new Audio(source)
        audio.setAttribute('preload', 'metadata')
        audio.addEventListener('loadedmetadata', loadedMetadata)
    })


}
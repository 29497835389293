import React, { useRef, useState } from 'react'
import styled from 'styled-components'

const HoveredProgressComplete = styled.div`

`

const ProgressContainer = styled.div`
    /* &:hover ${HoveredProgressComplete} {
        display: flex;
    } */
`


const ProgressBar = ({ percentComplete, onSeek, primaryColor, secondaryColor }) => {

    const progressContainer = useRef()
    const [hoveredSongPosition, setHoveredSongPosition] = useState(0)

    const onProgressHover = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const hoveredPosition = (x / progressContainer.current.clientWidth) * 100
        setHoveredSongPosition(hoveredPosition)
    }

    return (
        <div
            ref={progressContainer}
            onMouseMove={onProgressHover}
            onClick={() => onSeek(hoveredSongPosition)}
            className="w-full cursor-pointer">
            <div style={{ background: secondaryColor }} className="overflow-hidden h-2 text-xs flex rounded">
                <div style={{ width: percentComplete + '%', background: primaryColor }} className="hover:none shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"></div>
                {/* <HoveredProgressComplete style={{ width: hoveredSongPosition + '%', background: primaryColor || '#4FD1C5' }} className="hidden flex-col shadow-none text-center whitespace-nowrap text-white justify-center"></HoveredProgressComplete> */}
            </div>
        </div>
    )
}

export default ProgressBar

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import * as Color from 'color';
import { useAudioState } from '../../state/AudioWrapper'

const PlaybackControls = styled.div`

`

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    &:hover ${PlaybackControls} {
        display: flex;
    }
`
const AlbumArtContainer = styled.div`
    width: 60px;
`

const ProgressComplete = styled.div`

`

const HoveredProgressComplete = styled.div`

`

const ProgressContainer = styled.div`
    &:hover ${HoveredProgressComplete} {
        display: flex;
    }
`

const pad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.round((time - minutes * 60));
    return `${minutes}:${pad(seconds, 2)}`
}

const MiniPlayer = ({ artSrc, audioSrc, primaryColor, secondaryColor, title, artist, maxWidth }) => {

    useEffect(() => {
        // if (!audioSrc) return;
        // const audio = new Audio(audioSrc);
        // audio.setAttribute('preload', 'metadata')
        // const loadedMetadata = () => {
        //     setDuration(audio.duration)
        // }
        // audio.addEventListener('loadedmetadata', loadedMetadata)
        // return (() => {
        //     audio.removeEventListener('loadedmetadata', loadedMetadata)
        // })

    }, [audioSrc])

    const {
        currentAudioSource,
        isPlaying,
        duration,
        positionPercent,
        positionSeconds,
        setPositionSeconds,
        // setPositionPercent,
        loadAudio,
        playAudio,
        pauseAudio
    } = useAudioState()

    const isLight = Color(secondaryColor).isLight()
    const progressContainer = useRef();
    const [hoveredSongPosition, setHoveredSongPosition] = useState(0)

    const onProgressHover = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const hoveredPosition = (x / progressContainer.current.clientWidth) * 100
        setHoveredSongPosition(hoveredPosition)
    }

    const onProgressClick = () => {
        if (currentAudioSource !== audioSrc) {
            loadAudio(audioSrc)
        }
        setPositionSeconds(duration * hoveredSongPosition / 100)
    }

    const onClick = () => {
        if (currentAudioSource !== audioSrc) {
            loadAudio(audioSrc)
            playAudio()
        } else {
            isPlaying ? pauseAudio() : playAudio()
        }
    }

    const isCurrentSong = currentAudioSource === audioSrc

    return (
        <Container style={{ background: secondaryColor, maxWidth: maxWidth }} className={`${isLight ? 'text-gray-900' : 'text-white'} rounded-lg px-6 py-4 w-full h-full`}>
            <AlbumArtContainer className="rounded-sm mr-3 flex justify-center items-center cursor-pointer relative">
                <img style={{ width: 60 }} className="max-w-none object-cover" src={artSrc} alt="album artwork" />
                <div
                    onClick={onClick}
                    className={`absolute flex justify-center items-center w-full h-full hover:flex hover:bg-gray-600 hover:bg-opacity-25 ${isCurrentSong && isPlaying ? 'block bg-gray-500 bg-opacity-25' : 'hidden'}`}>
                    {(isCurrentSong && isPlaying) && <FontAwesomeIcon style={{ opacity: .8, color: '#fff' }} icon={faPause} size="lg" />}
                    {(!isPlaying || !isCurrentSong) && <FontAwesomeIcon style={{ opacity: .8, color: '#fff' }} icon={faPlay} size="lg" />}
                </div>
            </AlbumArtContainer>
            <div style={{ height: 60 }} className="flex flex-col justify-between items-between w-full h-full">
                <div className={`${isLight ? 'text-gray-900' : 'text-white'} flex flex-row justify-between items-end`}>
                    <div>
                        <h3 className="text-sm font-bold">{title}</h3>
                        <h5 className="text-xs">{artist}</h5>
                    </div>
                    {duration && <div className="text-xs">
                        {isCurrentSong &&
                            ` ${formatTime(positionSeconds)} / ${formatTime(duration)}`
                        }
                        {!isCurrentSong &&
                            ` ${formatTime(0)} / ${formatTime(duration)}`
                        }
                    </div>}
                </div>
                <ProgressContainer
                    ref={progressContainer}
                    onMouseMove={onProgressHover}
                    onClick={onProgressClick}
                    className="w-full cursor-pointer">
                    <div style={{ background: Color(primaryColor || '#4FD1C5').lighten(.4).hex() }} className="overflow-hidden h-2 text-xs flex rounded">
                        <ProgressComplete style={{ width: isCurrentSong ? positionPercent + '%' : 0, background: primaryColor || '#4FD1C5' }} className="hover:none shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"></ProgressComplete>
                        {/* <HoveredProgressComplete style={{ width: hoveredSongPosition + '%', background: primaryColor || '#4FD1C5' }} className="hidden flex-col shadow-none text-center whitespace-nowrap text-white justify-center"></HoveredProgressComplete> */}
                    </div>
                </ProgressContainer>
            </div>
        </Container>
    )
}

export default MiniPlayer

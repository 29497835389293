export const surfaceTension = {
    title: 'Surface Tension',
    subtitle: 'Good Field',
    imageSource: require('../images/surface-tension.jpg'),
    items: [
        { title: 'Necessary Feeling', artist: 'Good Field', audioSource: require('../audio/necessary-feeling.mp3'), imageSource: require('../images/surface-tension.jpg') },
        { title: 'Ordinary People', artist: 'Good Field', audioSource: require('../audio/ordinary-people.mp3'), imageSource: require('../images/surface-tension.jpg') },
        { title: 'Naked and Asleep', artist: 'Good Field', audioSource: require('../audio/naked-and-asleep.mp3'), imageSource: require('../images/surface-tension.jpg') },
        { title: 'All This Time', artist: 'Good Field', audioSource: require('../audio/all-this-time.mp3'), imageSource: require('../images/surface-tension.jpg') },
        { title: 'Endless Nights', artist: 'Good Field', audioSource: require('../audio/endless-nights.mp3'), imageSource: require('../images/surface-tension.jpg') },
        { title: 'Surface Tension', artist: 'Good Field', audioSource: require('../audio/surface-tension.mp3'), imageSource: require('../images/surface-tension.jpg') },
        { title: 'I Can See for Years', artist: 'Good Field', audioSource: require('../audio/i-can-see-for-years.mp3'), imageSource: require('../images/surface-tension.jpg') },
        { title: 'I Feel Off', artist: 'Good Field', audioSource: require('../audio/i-feel-off.mp3'), imageSource: require('../images/surface-tension.jpg') },
        { title: 'Sparkle Playground', artist: 'Good Field', audioSource: require('../audio/sparkle-playground.mp3'), imageSource: require('../images/surface-tension.jpg') },
        { title: 'Sometimes', artist: 'Good Field', audioSource: require('../audio/sometimes.mp3'), imageSource: require('../images/surface-tension.jpg') },
    ]
}

export const futureMe = {
    title: 'Future Me',
    subtitle: 'Good Field',
    imageSource: require('../images/future-me.jpg'),
    items: [
        { title: 'Future Me', artist: 'Good Field', audioSource: require('../audio/future-me.mp3'), imageSource: require('../images/future-me.jpg') },
        { title: 'Business', artist: 'Good Field', audioSource: require('../audio/business.mp3'), imageSource: require('../images/future-me.jpg') },
        { title: 'Control', artist: 'Good Field', audioSource: require('../audio/control.mp3'), imageSource: require('../images/future-me.jpg') },
        { title: 'Hospital Bed', artist: 'Good Field', audioSource: require('../audio/hospital-bed.mp3'), imageSource: require('../images/future-me.jpg') },
        { title: 'Telecommunication', artist: 'Good Field', audioSource: require('../audio/telecommunication.mp3'), imageSource: require('../images/future-me.jpg') },
        { title: 'Holdin\' On', artist: 'Good Field', audioSource: require('../audio/holdin-on.mp3'), imageSource: require('../images/future-me.jpg') },
        { title: 'Wait', artist: 'Good Field', audioSource: require('../audio/wait.mp3'), imageSource: require('../images/future-me.jpg') },
        { title: 'Tonight', artist: 'Good Field', audioSource: require('../audio/tonight.mp3'), imageSource: require('../images/future-me.jpg') },
        { title: 'At the Airport', artist: 'Good Field', audioSource: require('../audio/at-the-airport.mp3'), imageSource: require('../images/future-me.jpg') },
        { title: 'Do It', artist: 'Good Field', audioSource: require('../audio/do-it.mp3'), imageSource: require('../images/future-me.jpg') },
    ]
}

export const goodFieldDebut = {
    title: 'Debut Album',
    subtitle: 'Good Field',
    imageSource: require('../images/goodfield-album-art.jpg'),
    items: [
        { title: 'Find a Way', artist: 'Good Field', audioSource: require('../audio/find-a-way.mp3'), imageSource: require('../images/goodfield-album-art.jpg') },
        { title: 'Our Roofless Home', artist: 'Good Field', audioSource: require('../audio/our-roofless-home.mp3'), imageSource: require('../images/goodfield-album-art.jpg') },
        { title: 'Something\'s Different', artist: 'Good Field', audioSource: require('../audio/somethings-different.mp3'), imageSource: require('../images/goodfield-album-art.jpg') },
        { title: 'Panama', artist: 'Good Field', audioSource: require('../audio/panama.mp3'), imageSource: require('../images/goodfield-album-art.jpg') },
        { title: 'Tell Me Ida', artist: 'Good Field', audioSource: require('../audio/tell-me-ida.mp3'), imageSource: require('../images/goodfield-album-art.jpg') },
        { title: 'Gimme a Reason', artist: 'Good Field', audioSource: require('../audio/gimme-a-reason.mp3'), imageSource: require('../images/goodfield-album-art.jpg') },
        { title: 'These Dreams', artist: 'Good Field', audioSource: require('../audio/these-dreams.mp3'), imageSource: require('../images/goodfield-album-art.jpg') },
        { title: 'You Notice', artist: 'Good Field', audioSource: require('../audio/you-notice.mp3'), imageSource: require('../images/goodfield-album-art.jpg') },
        { title: 'Let Me Go', artist: 'Good Field', audioSource: require('../audio/let-me-go.mp3'), imageSource: require('../images/goodfield-album-art.jpg') },
        { title: 'When You Walk', artist: 'Good Field', audioSource: require('../audio/when-you-walk.mp3'), imageSource: require('../images/goodfield-album-art.jpg') },
        { title: 'A Hill Away', artist: 'Good Field', audioSource: require('../audio/a-hill-away.mp3'), imageSource: require('../images/goodfield-album-art.jpg') },
    ]
}
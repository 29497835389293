import React, { useState, useEffect } from 'react'
import * as Color from 'color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AudioListItem from '../primitives/AudioListItem'
import { useAudioState } from '../../state/AudioWrapper'
import { getAudioDuration } from '../../utils/getAudioDuration'
import styled from 'styled-components'
import Loading from '../primitives/Loading'
import PlaylistHeader from '../primitives/PlaylistHeader'

const TracksContainer = styled.div.attrs({
    className: "w-full h-full overflow-y-auto"
})`
    height: calc(100% - 6rem);
    &::-webkit-scrollbar-thumb {
	    background-color: #c8c8c8;
        border-radius: 1rem;
    }
    &::-webkit-scrollbar-thumb:hover {
	    background-color: #9e9e9e;
    }
    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }
`

const defaultSecondaryColor = '#1A202C';
const defaultTitleColor = '#fff';

const Title = styled.h1`
    @media(min-width: 1px) {
        font-size: 2.25rem;
    }

    @media(min-width: 1024px) {
        font-size: 6rem;
    }
`

const Subtitle = styled.h2`
    @media(min-width: 1px) {
        font-size: 1.125rem;
    }

    @media(min-width: 1024px) {
        font-size: 3rem;
    }
`

const PlaylistPlayer = ({ playlist, theme, headless }) => {

    // todo: theming
    const [loaded, setLoaded] = useState(false)
    const [tracks, setTracks] = useState()

    useState(() => {
        function ensureDurations() {
            return Promise.all(
                playlist.items.map(async (track) => {
                    if (track.duration) return track
                    if (!track.duration) {
                        const duration = await getAudioDuration(track.audioSource)
                        return { ...track, duration }
                    }
                })
            )
        }
        ensureDurations().then((tracks) => {
            setTracks(tracks)
            setLoaded(true)
        })
    }, [playlist])

    const {
        currentAudioSource,
        isPlaying,
        // duration,
        // positionPercent,
        // positionSeconds,
        // setPositionSeconds,
        // setPositionPercent,
        loadAudio,
        playAudio,
        pauseAudio
    } = useAudioState()

    const onTogglePlay = (audioSrc) => {
        if (currentAudioSource !== audioSrc) {
            loadAudio(audioSrc)
            playAudio()
        } else {
            isPlaying ? pauseAudio() : playAudio()
        }
    }

    return (
        <div className="w-full h-full">
            {!headless &&
                <div className="border-b-2 pb-4 h-24">
                    <PlaylistHeader
                        title={playlist.title}
                        subtitle={playlist.subtitle}
                        imageSource={playlist.imageSource}
                        trackCount={playlist.items.length}
                    />
                </div>
            }
            <TracksContainer>
                {!loaded && <Loading />}
                {loaded && tracks &&
                    (
                        <>
                            {tracks.map((track) => {
                                const isCurrentSong = currentAudioSource === track.audioSource
                                return (
                                    <AudioListItem
                                        key={track.audioSource}
                                        audioTrack={track}
                                        playing={isCurrentSong && isPlaying}
                                        selected={isCurrentSong}
                                        onTogglePlay={onTogglePlay}
                                    />
                                )
                            })}
                        </>
                    )
                }

            </TracksContainer>
        </div>

    )
}

export default PlaylistPlayer

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

const Sidebar = () => {
    const [collapseShow, setCollapseShow] = useState("hidden");
    const closeMenu = () => setCollapseShow("hidden")
    return (
        <>
            <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
                <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
                    <button
                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"
                        onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
                    >
                        <FontAwesomeIcon icon={faBars} size="lg" />
                    </button>
                    <Link
                        className="flex justify-end md:justify-start items-center text-left md:pb-2 text-gray-700 mr-0 whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                        to="/"
                        onClick={closeMenu}
                    >
                        <img className="w-3/12 md:w-4/12 mr-3" src={require('../images/logo2.svg')} alt="logo" />
                        <span>Flex Audio</span>
                    </Link>
                    <div className={"md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " + collapseShow}>
                        <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
                            <div className="flex flex-row justify-between">
                                <div className="w-5/12">
                                    <Link
                                        className="flex justify-start items-center text-left md:pb-2 text-gray-700 mr-0 whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                                        to="/"
                                        onClick={closeMenu}
                                    >
                                        <img className="w-4/12 mr-2" src={require('../images/logo2.svg')} alt="logo" />
                                        Flex Audio
                                    </Link>
                                </div>
                                <div className="w-4/12 flex flex-row justify-end">
                                    <button
                                        type="button"
                                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                        onClick={closeMenu}
                                    >
                                        <FontAwesomeIcon icon={faTimes} size="lg" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-3 no-underline">
                            Components
                        </h6>
                        <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                            <li className="items-center">
                                <Link
                                    to="/mini"
                                    className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 ml-2 font-bold block"
                                    onClick={closeMenu}
                                >
                                    Mini Player
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link
                                    to="/playlist"
                                    className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 ml-2 font-bold block"
                                    onClick={closeMenu}
                                >
                                    Playlist
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link
                                    to="/footer"
                                    className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 ml-2 font-bold block"
                                    onClick={closeMenu}
                                >
                                    Footer
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link
                                    to="/button"
                                    className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 ml-2 font-bold block"
                                    onClick={closeMenu}
                                >
                                    Button
                                </Link>
                            </li>
                            {/* <li className="items-center">
                                <NavItem title="Playground" to="/playground" />
                            </li> */}

                        </ul>
                        <hr className="my-4 md:min-w-full" />
                        <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Code
                        </h6>
                        <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                            <li className="items-center">
                                <a className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 ml-2 font-bold block" href="https://github.com/tubatoothpaste/audio-players">Github</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Sidebar
import React from 'react'
import ButtonPlayer from '../components/players/AudioButton'
import MiniPlayer from '../components/players/Mini'
import { useAudioState } from '../state/AudioWrapper'

const buttons = [
    { title: 'These Dreams', artist: 'Good Field', audioSrc: require('../audio/these-dreams.mp3'), imageSrc: require('../images/goodfield-album-art.jpg') },
    { title: 'Necessary Feeling', artist: 'Good Field', audioSrc: require('../audio/necessary-feeling.mp3'), imageSrc: require('../images/surface-tension.jpg') }
]

const players = [
    { title: 'Business', artist: 'Good Field', audioSrc: require('../audio/business.mp3'), imageSrc: require('../images/future-me.jpg') },
]

const Playground = () => {

    const { loadAudio, playAudio } = useAudioState()

    const onTrack = (src) => {
        loadAudio(src)
        playAudio()
    }

    return (
        <div>
            {buttons.map((song, i) => {
                return (
                    <div key={i} className="m-3">
                        <ButtonPlayer
                            primaryColor="#63B3ED"
                            secondaryColor="#F7FAFC"
                            src={song.audioSrc}
                            size={100}
                            imageSrc={song.imageSrc}
                            iconColor={'#F7FAFC'}
                        />
                    </div>
                )
            })}

            {players.map((song, i) => {
                return (
                    <div key={i} className="m-3">
                        <MiniPlayer
                            maxWidth={400}
                            title={song.title}
                            artist={song.artist}
                            primaryColor="#2B6CB0"
                            secondaryColor="#CBD5E0"
                            artSrc={song.imageSrc}
                            audioSrc={song.audioSrc}
                        />
                    </div>
                )
            })}
            <div className="p-3">
                <button onClick={() => onTrack(require('../audio/these-dreams.mp3'))} className="block">These Dreams</button>
                <button onClick={() => onTrack(require('../audio/business.mp3'))} className="block">Business</button>
                <button onClick={() => onTrack(require('../audio/necessary-feeling.mp3'))} className="block">Necessary Feeling</button>
            </div>

        </div>
    )
}

export default Playground

import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faUser, faBars, faVolumeUp, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import './App.css'
import MiniPage from './views/MiniPage';
import FooterPage from './views/FooterPage';
import PlaylistPage from './views/PlaylistPage';
import ButtonPage from './views/ButtonPage';
import About from './views/About';
import Resume from './views/Resume';
import Sidebar from './components/Sidebar';
import Home from './views/Home';
import Playground from './views/Playground';
import { AudioWrapper } from './state/AudioWrapper';

library.add(fab, faUser, faBars, faVolumeUp, faPaperPlane)


function App() {
  return (
    <div className="w-full">
      <AudioWrapper>
        <Router>
          <Sidebar />
          <div className="relative md:ml-64">
            <Switch>
              <Route path="/mini">
                <MiniPage />
              </Route>
              <Route path="/footer">
                <FooterPage />
              </Route>
              <Route path="/playlist">
                <PlaylistPage />
              </Route>
              <Route path="/button">
                <ButtonPage />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/resume">
                <Resume />
              </Route>
              <Route path="/playground">
                <Playground />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </Router>
      </AudioWrapper>
    </div>
  );
}

export default App;

import React from 'react';
import PageHeader from '../components/PageHeader';
import PageContent from '../components/PageContent';
import PageTitle from '../components/PageTitle';
import FooterPlayer from '../components/players/Footer';
import AudioButton from '../components/players/AudioButton';
import AudioListItem from '../components/primitives/AudioListItem'

const playlistItems = [
    { title: 'Necessary Feeling', artist: 'Good Field', audioSource: require('../audio/necessary-feeling.mp3'), imageSource: require('../images/surface-tension.jpg') },
    { title: 'Holdin\' On', artist: 'Good Field', audioSource: require('../audio/holdin-on.mp3'), imageSource: require('../images/future-me.jpg') },
    { title: 'You Notice', artist: 'Good Field', audioSource: require('../audio/you-notice.mp3'), imageSource: require('../images/goodfield-album-art.jpg') },
]

const FooterPage = () => {

    return (
        <div>
            <PageHeader>
                <PageTitle>Footer</PageTitle>
                <p>A player fixed to the bottom of the page.</p>
            </PageHeader>
            <PageContent>
                <div className="flex flex-col xl:flex-row">
                    <div className="w-full flex flex-col mb-4 xl:mb-8 px-1 md:px-0">
                        <div className="w-full px-4 py-6 flex flex-col items-start justify-between xl:flex-row xl:justify-center xl:items-center min-w-0 break-words bg-white rounded-lg mb-6 shadow-lg">
                            <div className="w-full xl:w-7/12 mb-6">
                                <h3 className="text-gray-700 uppercase font-bold text-lg mb-4">Description</h3>
                                <div className="text-sm text-gray-600">
                                    <div className="w-6/12 lg:w-4/12 max-w-xs px-4 sm:px-10 md:px-3 lg:px-0">
                                        <AudioButton
                                            primaryColor="#5fc2ff"
                                            secondaryColor="#F7FAFC"
                                            audioTrack={{
                                                title: 'These Dreams',
                                                artist: 'Good Field',
                                                albumTitle: 'Debut Album',
                                                audioSource: require('../audio/these-dreams.mp3'),
                                                imageSource: require('../images/goodfield-vinyl.png')
                                            }}
                                            displayBackgroundImage
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="w-full px-4 py-6 flex flex-col items-start justify-between xl:flex-row xl:justify-center xl:items-center min-w-0 break-words bg-white rounded-lg mb-6 xl:mb-0 shadow-lg">

                            <div className="flex flex-col justify-start items-start w-full xl:w-6/12">
                                {playlistItems.map((track) => (
                                    <AudioListItem
                                        key={track.audioSource}
                                        audioTrack={track}
                                    />
                                ))}

                            </div>
                        </div>
                    </div>
                </div>


                <div className="flex flex-col justify-center items-center w-full xl:w-6/12">
                    <div className="w-8/12 sm:w-5/12 xl:w-full px-16">
                        <FooterPlayer
                            primaryColor="#5fc2ff"
                            secondaryColor="#1A202C"
                            timeline="waveform"
                            audioTrack={{
                                title: 'Business',
                                artist: 'Good Field',
                                audioSource: require('../audio/business.mp3'),
                                imageSource: require('../images/future-me.jpg')
                            }} defaultAudioSrc={require('../audio/business.mp3')}
                        />
                    </div>
                </div>
            </PageContent>
        </div>
    )
}

export default FooterPage

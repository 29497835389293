import { useState } from 'react'

export const useAudioDetails = () => {
    const [audioDetails, setAudioDetails] = useState()
    const [title, setTitle] = useState()
    const [artist, setArtist] = useState()
    const [albumTitle, setAlbumTitle] = useState()
    const [imageSource, setImageSource] = useState()
    const [duration, setDuration] = useState()

    return { title, artist, albumTitle, imageSource, duration, setTitle, setArtist, setAlbumTitle, setImageSource, setDuration }
}
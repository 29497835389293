import React, { createContext, useContext } from 'react'
import { useWebAudio } from './useWebAudio'
import { useAudioDetails } from './useAudioDetails'


export const AudioContext = createContext()

export const AudioWrapper = ({ children }) => {

    const {
        context,
        audio,
        currentAudioSource,
        isPlaying,
        positionPercent,
        positionSeconds,
        setPositionSeconds,
        setPositionPercent,
        loadAudio,
        playAudio,
        pauseAudio
    } = useWebAudio();

    const {
        title,
        artist,
        albumTitle,
        imageSource,
        duration,
        setTitle,
        setArtist,
        setAlbumTitle,
        setImageSource,
        setDuration
    } = useAudioDetails()

    return (
        <AudioContext.Provider
            value={{
                context,
                audio,
                currentAudioSource,
                isPlaying,
                positionPercent,
                positionSeconds,
                setPositionSeconds,
                setPositionPercent,
                loadAudio,
                playAudio,
                pauseAudio,
                title,
                artist,
                albumTitle,
                imageSource,
                duration,
                setTitle,
                setArtist,
                setAlbumTitle,
                setImageSource,
                setDuration
            }}
        >
            {children}
        </AudioContext.Provider>
    )

}

export const useAudioState = () => useContext(AudioContext)

import React, { useEffect, useRef, useState } from 'react'
import * as WaveSurfer from 'wavesurfer.js'
import Loading from './Loading'
import * as Color from 'color';

const Waveform = ({ color, audioSrc, audioContext, positionPercent }) => {
    const waveformEl = useRef()
    const [loadingWave, setLoadingWave] = useState(true)
    const [wavesurfer, setWavesurfer] = useState()
    useEffect(() => {
        if (wavesurfer) return

        const progressGradient = document.createElement('canvas').getContext('2d').createLinearGradient(0, 0, 1000, 128);
        progressGradient.addColorStop(0, Color(color).lighten(.8).hex());
        progressGradient.addColorStop(1, Color(color).lighten(.5).hex());
        const wavesurferInit = WaveSurfer.create({
            audioContext,
            barWidth: 2,
            height: 50,
            container: waveformEl.current,
            responsive: true,
            waveColor: color,
            progressColor: progressGradient,
            // progressColor: Color(color).darken(.4).hex(),
            cursorWidth: 0,
            // cursorColor: Color(color).darken(.1).hex(),
            barGap: 3,
        });
        setWavesurfer(wavesurferInit)
        return (() => {
        })
    }, [audioContext, color])

    useEffect(() => {
        if (!wavesurfer) return
        wavesurfer.load(audioSrc)
    }, [wavesurfer, audioSrc])

    useEffect(() => {
        if (!wavesurfer) return
        const onReady = () => {
            setLoadingWave(false)
        }
        const onLoading = () => {
            setLoadingWave(true)
        }
        wavesurfer.on('ready', onReady)
        wavesurfer.on('loading', onLoading)
        return (() => {
            wavesurfer.un('ready', onReady)
            wavesurfer.un('loading', onLoading)
        })
    }, [wavesurfer])

    useEffect(() => {
        if (!wavesurfer) return
        wavesurfer.seekTo(positionPercent / 100)
    }, [wavesurfer, positionPercent])
    return (
        <>
            <div className={`w-full`} ref={waveformEl}></div>
            {loadingWave && <div style={{ bottom: 25 }} className="absolute inset-x-0">
                <Loading />
            </div>}
        </>
    )
}

export default Waveform

import React from 'react';
import PageHeader from '../components/PageHeader';
import PageContent from '../components/PageContent';
import PageTitle from '../components/PageTitle';
import MiniPlayer from '../components/players/Mini';

const MiniPage = () => {
    return (
        <div>
            <PageHeader>
                <PageTitle>Mini Player</PageTitle>
                <p>A minimal audio player with track info and album artwork.</p>
            </PageHeader>
            <PageContent>
                <div className="flex flex-col xl:flex-row">
                    <div className="w-full flex flex-row mb-0 xl:mb-8 px-1 md:px-0">
                        <div className="w-full px-4 py-6 flex flex-col items-start justify-between xl:flex-row xl:justify-center xl:items-center min-w-0 break-words bg-white rounded-lg mb-6 xl:mb-0 shadow-lg">
                            <div className="w-full xl:w-7/12 mb-6">
                                <h3 className="text-gray-700 uppercase font-bold text-lg mb-4">Description</h3>
                                <div className="text-sm text-gray-600">
                                    <p className="mb-3">This component can be used to highlight individual tracks. The colors are customizable, and has play, pause and seek functionality.</p>
                                    <p>The component will fill its parent container, allowing for a responsive display.</p>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center w-full xl:w-6/12">
                                <div className="w-full sm:w-7/12 md:w-10/12 lg:w-8/12 xl:w-full md:px-16">
                                    <MiniPlayer
                                        title="Business"
                                        artist="Good Field"
                                        secondaryColor="#EDF2F7"
                                        artSrc={require('../images/future-me.jpg')}
                                        audioSrc={require('../audio/business.mp3')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col xl:flex-row">
                    <div className="w-full flex flex-row mb-4 xl:mb-8 px-1 md:px-0">
                        <div className="w-full px-4 py-6 flex flex-col items-start justify-between min-w-0 break-words bg-white rounded-lg mb-6 xl:mb-0 shadow-lg">
                            <div className="w-full mb-6">
                                <h3 className="text-gray-700 uppercase font-bold text-lg mb-4">Color Variations</h3>
                                <div className="text-sm text-gray-600 max-w-screen-sm">
                                    <p className="mb-3">Primary and secondary colors are customizable, with the primary color applied to the progress bar, and the secondary color applied to the background.</p>
                                    <p>The secondary color hue is analyzed inside the component to determine the text color. Providing a darker color will produce light text, and a lighter color will produce dark text.</p>
                                </div>
                            </div>
                            <div className="w-full sm:w-11/12 lg:w-9/12 xl:w-6/12 flex flex-col md:flex-row md:justify-start md:items-center mx-auto mb-6">
                                <MiniPlayer
                                    title="Business"
                                    artist="Good Field"
                                    secondaryColor="#4A5568"
                                    primaryColor="#ECC94B"
                                    artSrc={require('../images/future-me.jpg')}
                                    audioSrc={require('../audio/business.mp3')}
                                />
                                <div className="flex flex-col justify-center text-xs text-center md:text-left font-bold mt-6 md:mt-0 md:ml-3">
                                    <div className="mb-4">
                                        <span className="text-gray-700 mr-1 p-1">Primary</span>
                                        <span className="text-white rounded-md px-2 py-1" style={{ background: '#ECC94B' }}>#ECC94B</span>
                                    </div>
                                    <div>
                                        <span className="text-gray-700 mr-1 p-1">Secondary</span>
                                        <span className="text-white rounded-md px-2 py-1" style={{ background: '#4A5568' }}>#4A5568</span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full sm:w-11/12 lg:w-9/12 xl:w-6/12 flex flex-col md:flex-row md:justify-start md:items-center mx-auto mb-6">
                                <MiniPlayer
                                    title="Business"
                                    artist="Good Field"
                                    secondaryColor="#EBF8FF"
                                    primaryColor="#4299E1"
                                    artSrc={require('../images/future-me.jpg')}
                                    audioSrc={require('../audio/business.mp3')}
                                />
                                <div className="flex flex-col justify-center text-xs text-center md:text-left font-bold mt-6 md:mt-0 md:ml-3">
                                    <div className="mb-4">
                                        <span className="text-gray-700 mr-1 p-1">Primary</span>
                                        <span className="text-white rounded-md px-2 py-1" style={{ background: '#4299E1' }}>#4299E1</span>
                                    </div>
                                    <div>
                                        <span className="text-gray-700 mr-1 p-1">Secondary</span>
                                        <span className="text-gray-800 rounded-md px-2 py-1" style={{ background: '#EBF8FF' }}>#EBF8FF</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </PageContent>
        </div >
    )
}

export default MiniPage

import React from 'react'

const PageContent = ({ children }) => {
    return (
        <div className="px-2 md:px-4 py-3 md:py-4 xl:py-8 mx-auto w-full max-w-6xl text-gray-800">
            {children}
        </div>
    )
}

export default PageContent

import React from 'react'
import { Link } from 'react-router-dom';
import AudioButton from '../components/players/AudioButton';
import PageHeader from '../components/PageHeader';
import PageContent from '../components/PageContent';
import PageTitle from '../components/PageTitle';

const ButtonPage = () => {
    return (
        <>
            <PageHeader>
                <PageTitle>Button</PageTitle>
                <p className="text-sm">A minimal audio player that displays the current position wrapped around a circular button.</p>
            </PageHeader>
            <PageContent>
                <div className="flex flex-col xl:flex-row">

                    <div className="w-full flex flex-row mb-0 xl:mb-8">
                        <div className="w-full px-4 py-6 flex flex-col items-start justify-between xl:flex-row xl:justify-center xl:items-start min-w-0 break-words bg-white rounded-lg mb-6 xl:mb-0 shadow-lg">
                            <div className="w-full xl:w-8/12 mb-6">
                                <h3 className="text-gray-700 uppercase font-bold text-lg mb-4">Description</h3>
                                <div className="text-sm text-gray-600">
                                    <p className="mb-4">This component is a simple button that provides play and pause controls for an audio track, and displays the progress as an outer ring. The colors are customizable, and a background image can be optionally included.</p>
                                    <p className="mb-4">The button will fill the container it's placed in, making it simple to implement a responsive design.</p>
                                    <p>This component it doesn't have any seek controls to move around the track. It's intended to be used alongside a <Link className="link" to="/footer">Footer Component</Link> or within a <Link className="link" to="/playlist">Playlist</Link> as a part of a collection of tracks, where the song position can be controlled in a more useful manner.</p>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center w-full xl:w-4/12">
                                <div className="w-3/12 md:w-2/12 xl:w-10/12 xl:px-24">
                                    <AudioButton
                                        primaryColor="#3182CE"
                                        secondaryColor="#C3DAFE"
                                        audioTrack={{
                                            title: 'These Dreams',
                                            artist: 'Good Field',
                                            albumTitle: 'Debut Album',
                                            audioSource: require('../audio/these-dreams.mp3'),
                                            imageSource: require('../images/goodfield-vinyl.png')
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col items-center justify-center text-sm text-gray-500">
                                    <div className="flex flex-row">
                                        <h5 className="title-column text-md text-gray-500 font-bold uppercase mr-2">Artist</h5>
                                        <h5 className="text-md text-gray-700 font-bold uppercase">Good Field</h5>
                                    </div>
                                    <div className="flex flex-row">
                                        <h5 className="title-column text-md text-gray-500 font-bold uppercase mr-2">Song</h5>
                                        <h5 className="text-md text-gray-700 font-bold uppercase">These Dreams</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-0 xl:mb-8">
                    <div className="w-full">
                        <div className="relative flex flex-col min-w-0 break-words bg-white rounded-lg mb-6 xl:mb-0 shadow-lg">
                            <div className="flex-auto px-4 py-6">
                                <div className="mb-10">
                                    <h3 className="text-gray-700 uppercase font-bold text-lg mb-1">Color Variations</h3>
                                    <p className="text-sm text-gray-600">Primary and Secondary colors can be set to customize the color scheme.</p>
                                </div>
                                <div className="grid grid-cols-2 lg:grid-cols-4 items-center gap-4">
                                    <div className="flex flex-col w-full justify-center items-center mb-4">
                                        <div className="w-8/12 max-w-xs px-4 sm:px-10 md:px-3 lg:px-0">
                                            <AudioButton
                                                primaryColor="#9F7AEA"
                                                secondaryColor="#D6BCFA"
                                                audioTrack={{
                                                    title: 'These Dreams',
                                                    artist: 'Good Field',
                                                    albumTitle: 'Debut Album',
                                                    audioSource: require('../audio/these-dreams.mp3'),
                                                    imageSource: require('../images/goodfield-vinyl.png')
                                                }}
                                            />
                                        </div>
                                        <div className="text-xs mt-4 text-center font-bold">
                                            <div className="mb-4">
                                                <span className="text-gray-700 mr-1 p-1">Primary</span>
                                                <span className="text-white rounded-md px-2 py-1" style={{ background: '#9F7AEA' }}>#9F7AEA</span>
                                            </div>
                                            <div className="mb-4">
                                                <span className="text-gray-700 mr-1 p-1">Secondary</span>
                                                <span className="text-gray-900 rounded-md px-2 py-1" style={{ background: '#D6BCFA' }}>#D6BCFA</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full justify-center items-center mb-4">
                                        <div className="w-8/12 max-w-xs px-4 sm:px-10 md:px-3 lg:px-0">
                                            <AudioButton
                                                primaryColor="#7F9CF5"
                                                secondaryColor="#C3DAFE"
                                                audioTrack={{
                                                    title: 'These Dreams',
                                                    artist: 'Good Field',
                                                    albumTitle: 'Debut Album',
                                                    audioSource: require('../audio/these-dreams.mp3'),
                                                    imageSource: require('../images/goodfield-vinyl.png')
                                                }}
                                            />
                                        </div>
                                        <div className="text-xs mt-4 text-center font-bold">
                                            <div className="mb-4">
                                                <span className="text-gray-700 mr-1 p-1">Primary</span>
                                                <span className="text-white rounded-md px-2 py-1" style={{ background: '#7F9CF5' }}>#7F9CF5</span>
                                            </div>
                                            <div className="mb-4">
                                                <span className="text-gray-700 mr-1 p-1">Secondary</span>
                                                <span className="text-gray-900 rounded-md px-2 py-1" style={{ background: '#C3DAFE' }}>#C3DAFE</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full justify-center items-center mb-4">
                                        <div className="w-8/12 max-w-xs px-4 sm:px-10 md:px-3 lg:px-0">
                                            <AudioButton
                                                primaryColor="#4FD1C5"
                                                secondaryColor="#B2F5EA"
                                                audioTrack={{
                                                    title: 'These Dreams',
                                                    artist: 'Good Field',
                                                    albumTitle: 'Debut Album',
                                                    audioSource: require('../audio/these-dreams.mp3'),
                                                    imageSource: require('../images/goodfield-vinyl.png')
                                                }}
                                            />
                                        </div>
                                        <div className="text-xs mt-4 text-center font-bold">
                                            <div className="mb-4">
                                                <span className="text-gray-700 mr-1 p-1">Primary</span>
                                                <span className="text-white rounded-md px-2 py-1" style={{ background: '#4FD1C5' }}>#4FD1C5</span>
                                            </div>
                                            <div className="mb-4">
                                                <span className="text-gray-700 mr-1 p-1">Secondary</span>
                                                <span className="text-gray-900 rounded-md px-2 py-1" style={{ background: '#B2F5EA' }}>#B2F5EA</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full justify-center items-center mb-4">
                                        <div className="w-8/12 max-w-xs px-4 sm:px-10 md:px-3 lg:px-0">
                                            <AudioButton
                                                primaryColor="#F6AD55"
                                                secondaryColor="#FEEBC8"
                                                audioTrack={{
                                                    title: 'These Dreams',
                                                    artist: 'Good Field',
                                                    albumTitle: 'Debut Album',
                                                    audioSource: require('../audio/these-dreams.mp3'),
                                                    imageSource: require('../images/goodfield-vinyl.png')
                                                }}
                                            />
                                        </div>
                                        <div className="text-xs mt-4 text-center font-bold">
                                            <div className="mb-4">
                                                <span className="text-gray-700 mr-1 p-1">Primary</span>
                                                <span className="text-white rounded-md px-2 py-1" style={{ background: '#F6AD55' }}>#F6AD55</span>
                                            </div>
                                            <div className="mb-4">
                                                <span className="text-gray-700 mr-1 p-1">Secondary</span>
                                                <span className="text-gray-900 rounded-md px-2 py-1" style={{ background: '#FEEBC8' }}>#FEEBC8</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-0 xl:mb-8">
                    <div className="w-full">
                        <div className="relative flex flex-col min-w-0 break-words bg-white rounded-lg mb-6 xl:mb-0 shadow-lg">
                            <div className="flex-auto px-4 py-6">
                                <div className="mb-10">
                                    <h3 className="text-gray-700 uppercase font-bold text-lg mb-1">Fixed Size Variations</h3>
                                    <p className="text-sm text-gray-600">Set the parent container to a fixed width to enforce a consistent size.</p>
                                </div>
                                <div className="flex flex-col lg:flex-row justify-between items-center w-full">
                                    <div style={{ width: 40 }} className="flex flex-row md:flex-col justify-start items-center mb-4 xl:ml-12">
                                        <AudioButton
                                            audioTrack={{
                                                title: 'These Dreams',
                                                artist: 'Good Field',
                                                albumTitle: 'Debut Album',
                                                audioSource: require('../audio/these-dreams.mp3'),
                                                imageSource: require('../images/goodfield-vinyl.png')
                                            }}
                                        />                                        <span className="text-gray-700 font-bold mt-2 text-xs ml-3 md:ml-0">40px</span>
                                    </div>
                                    <div style={{ width: 80 }} className="flex flex-row md:flex-col justify-start items-center mb-4">
                                        <AudioButton
                                            audioTrack={{
                                                title: 'These Dreams',
                                                artist: 'Good Field',
                                                albumTitle: 'Debut Album',
                                                audioSource: require('../audio/these-dreams.mp3'),
                                                imageSource: require('../images/goodfield-vinyl.png')
                                            }}
                                        />                                        <span className="text-gray-700 font-bold mt-2 ml-3 md:ml-0 text-sm">80px</span>
                                    </div>
                                    <div style={{ width: 160 }} className="flex flex-row md:flex-col justify-start items-center mb-4">
                                        <AudioButton
                                            audioTrack={{
                                                title: 'These Dreams',
                                                artist: 'Good Field',
                                                albumTitle: 'Debut Album',
                                                audioSource: require('../audio/these-dreams.mp3'),
                                                imageSource: require('../images/goodfield-vinyl.png')
                                            }}
                                        />                                        <span className="text-gray-700 font-bold mt-2 ml-3 md:ml-0 text-lg">160px</span>
                                    </div>
                                    <div style={{ width: 220 }} className="flex flex-row md:flex-col justify-start items-center mb-4 mr-12">
                                        <AudioButton
                                            audioTrack={{
                                                title: 'These Dreams',
                                                artist: 'Good Field',
                                                albumTitle: 'Debut Album',
                                                audioSource: require('../audio/these-dreams.mp3'),
                                                imageSource: require('../images/goodfield-vinyl.png')
                                            }}
                                        />
                                        <span className="text-gray-700 font-bold mt-2 ml-3 text-xl">220px</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-0 xl:mb-8">
                    <div className="w-full">
                        <div className="relative flex flex-col min-w-0 break-words bg-white rounded-lg mb-6 xl:mb-0 shadow-lg">
                            <div className="flex-auto px-4 py-6">
                                <div className="mb-10">
                                    <h3 className="text-gray-700 uppercase font-bold text-lg mb-1">Background Image</h3>
                                    <p className="text-sm text-gray-600 max-w-screen-sm">A optional background image can be placed behind each button. A translucent gradient overlay is added to help guarantee contrast between the button and background image.</p>
                                </div>
                                <div className="grid grid-cols-2 lg:grid-cols-4 items-center gap-4">
                                    <div className="flex flex-col w-full justify-center items-center mb-4">
                                        <div className="w-6/12 lg:w-4/12 max-w-xs px-4 sm:px-10 md:px-3 lg:px-0">
                                            <AudioButton
                                                displayBackgroundImage
                                                primaryColor="#90CDF4"
                                                secondaryColor="#F7FAFC"
                                                audioTrack={{
                                                    title: 'These Dreams',
                                                    artist: 'Good Field',
                                                    albumTitle: 'Debut Album',
                                                    audioSource: require('../audio/these-dreams.mp3'),
                                                    imageSource: require('../images/goodfield-vinyl.png')
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full justify-center items-center mb-4">
                                        <div className="w-6/12 lg:w-4/12 max-w-xs px-4 sm:px-10 md:px-3 lg:px-0">
                                            <AudioButton
                                                displayBackgroundImage
                                                primaryColor="#90CDF4"
                                                secondaryColor="#F7FAFC"
                                                audioTrack={{
                                                    title: 'These Dreams',
                                                    artist: 'Good Field',
                                                    albumTitle: 'Debut Album',
                                                    audioSource: require('../audio/these-dreams.mp3'),
                                                    imageSource: require('../images/goodfield-vinyl.png')
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full justify-center items-center mb-4">
                                        <div className="w-6/12 lg:w-4/12 max-w-xs px-4 sm:px-10 md:px-3 lg:px-0">
                                            <AudioButton
                                                displayBackgroundImage
                                                primaryColor="#90CDF4"
                                                secondaryColor="#F7FAFC"
                                                audioTrack={{
                                                    title: 'These Dreams',
                                                    artist: 'Good Field',
                                                    albumTitle: 'Debut Album',
                                                    audioSource: require('../audio/these-dreams.mp3'),
                                                    imageSource: require('../images/goodfield-vinyl.png')
                                                }}
                                            />
                                        </div>

                                    </div>
                                    <div className="flex flex-col w-full justify-center items-center mb-4">
                                        <div className="w-6/12 lg:w-4/12 max-w-xs px-4 sm:px-10 md:px-3 lg:px-0">
                                            <AudioButton
                                                displayBackgroundImage
                                                primaryColor="#90CDF4"
                                                secondaryColor="#F7FAFC"
                                                audioTrack={{
                                                    title: 'These Dreams',
                                                    artist: 'Good Field',
                                                    albumTitle: 'Debut Album',
                                                    audioSource: require('../audio/these-dreams.mp3'),
                                                    imageSource: require('../images/goodfield-vinyl.png')
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContent>
        </>
    )
}

export default ButtonPage
